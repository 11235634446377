import { store } from '../views/App'

const localization = {
  learn_more: {
    en: 'Learn more about:',
    es: 'Cónoce más:',
    it: 'Impariamo di più su:',
    de: 'Erfahren Sie mehr:',
    pt: 'Saber mais:',
    fr: 'Vous en apprendrez sur : ',
    hr: 'Nauči više o',
    zh: '学习更多关于:',
    th: 'คุณจะได้เรียนรู้เกี่ยวกับ:',
  },
  middle_school: {
    en: 'Middle school',
    es: 'Educación primaria',
    it: 'Scuola secondaria 1º grado',
    de: 'Unterstufe',
    fr: 'Collège',
  },
  high_school: {
    en: 'High school',
    es: 'Educación secundaria',
    it: 'Scuola secondaria 2º grado',
    de: 'Mittel- und Oberstufe',
    fr: 'Lycée',
  },
  university: {
    en: 'University',
    es: 'Universidad',
    it: 'Università',
    de: 'Universität',
    fr: 'Université',
  },
  getting_started: {
    en: 'Getting Started',
    es: 'Empezando',
    it: 'Iniziare',
    fr: 'Premiers pas',
  },
  products: {
    en: 'Products',
    es: 'Productos',
    it: 'Prodotti',
    de: 'Produkte',
    pt: 'Produtos',
    hr: 'Proizvodi',
    zh: '产品展示',
    el: 'Προϊόντα',
    fr: 'Produits',
  },
  projects: {
    en: 'Projects',
    es: 'Proyectos',
    it: 'Progetti',
    de: 'Projekte',
    pt: 'projectos',
    fr: 'Projets',
    zh: '活动项目',
  },
  activities: {
    en: 'Activities',
    es: 'Actividades',
    it: 'Attività',
    de: 'Aktivitäten',
    pt: 'Atividades',
    fr: 'Activités',
    hr: 'Aktivnosti',
    zh: '活动项目',
  },
  experiments: {
    en: 'Experiments',
    es: 'Experimentos',
    it: 'Esperimenti',
    de: 'Experimente',
    pt: 'Experiências',
    hu: 'Kísérletek',
  },
  lessons: {
    en: 'Lessons',
    es: 'Lecciones',
    el: 'Μαθήματα',
    it: 'Lezioni',
    de: 'Lektionen',
    pt: 'Lições',
    fr: 'Cours',
    hr: 'Lekcije',
    zh: '模块',
    th: 'บทเรียน',
  },
  previus_lesson: {
    en: 'Previous lesson',
    es: 'Leccion previa',
    it: 'Lezione precedente',
    de: 'Vorherige Lektion',
    pt: 'Lição anterior',
    fr: 'Leçon précédente',
    hr: 'Prethodna lekcija',
    zh: '上一课',
    th: 'บทเรียนก่อนหน้า',
  },
  previus_activities: {
    en: 'Previous activities',
    es: 'Actividad previa',
    it: 'Attività precedente',
    de: 'Vorherige aktivitäten',
    pt: 'Atividades anterior',
    fr: 'Activités précédente',
    hr: 'Prethodna aktivnosti',
    zh: '以前的活动',
  },
  webinar_booking: {
    en: 'Webinar booking',
    it: 'Prenota webinar',
    es: 'Webinars en vivo',
  },
  training: {
    en: 'Training',
    it: 'Formazione docenti',
    es: 'Curso de preparación',
  },
  educators: {
    en: 'Educators',
    it: 'Educatori',
    es: 'Educadores',
  },
  modules: {
    en: 'Modules',
    it: 'Moduli',
    es: 'Modules',
  },
}

export default function (key) {
  try {
    const selected = store.getState().root.profile.language || 'en'
    if (!localization[key]) {
      return ''
    }
    return localization[key][selected] || localization[key]['en'] || ''
  } catch (err) {
    return ''
  }
}
