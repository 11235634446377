import React from 'react'
import PropTypes from 'prop-types'

export function IntroModalBody({ onClose, title, description }) {
  return (
    <>
      <div className="modal__title">{title}</div>
      <div className="modal__description" dangerouslySetInnerHTML={{ __html: description }}></div>
      <div className="modal__buttons">
        <button className="button" onClick={onClose}>
          GOT IT
        </button>
      </div>
    </>
  )
}

IntroModalBody.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
}
