import fetch from '../utils/authenticatedFetch'
import { API_URL } from '../env'

export async function getKits() {
  const response = await fetch(`${API_URL}/education/v2/kits`)

  const data = await response.json()
  return data
}

export async function getKitsByOrganization() {
  const response = await fetch(`${API_URL}/classroom/v1/kits`)

  const data = await response.json()
  return data
}

export async function getSubscriptions() {
  const response = await fetch(`${API_URL}/restrictions/v1/recap/me`)
  const data = await response.json()
  return data
}

export async function getRoleFromOrganizations(userID) {
  const response = await fetch(`${API_URL}/classroom/v1/users/${userID}/organizations/roles`)
  const data = await response.json()
  return data
}
