import React from 'react'
import PropTypes from 'prop-types'

import './Modal.scss'

class Modal extends React.Component {
  render() {
    const className = `modal ${this.props.open ? 'open' : ''} ${this.props.className}`

    return (
      <div className={className}>
        <div className="modal__backdrop" onClick={this.props.onClose} role="button" />
        <div className="modal__body">{this.props.children}</div>
      </div>
    )
  }
}

Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
}

export default Modal
