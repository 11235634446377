import React, { useEffect } from 'react'
import Loader from '../views/ui-common/Loader/Loader'
import { useQuery } from '@apollo/client'
import { store } from '../views/App'
import { PROJECT } from '../env'
import CONFIG from '../configurator'
import { ProjectList } from './constants'

const defaultLang = 'en'

const detectRequiredFields = (object, path) => {
  return path.reduce((accumulator, currentValue) => {
    if (accumulator && accumulator[currentValue]) {
      accumulator = accumulator[currentValue]
    } else {
      accumulator = undefined
    }
    return accumulator
  }, object)
}

const QueryWrapper = (query, Component, props, variables = {}, settings = {}) => {
  const locale = store.getState().root.profile.language
  const { required } = settings
  const isContentPreview = PROJECT === ProjectList.CONTENTPREVIEW

  const extensionsLocales = CONFIG[PROJECT].langs?.extensions || new Set([])
  const extensionsLang = extensionsLocales.has(locale) ? locale : defaultLang
  const { data, refetch, loading, fetchMore, networkStatus } = useQuery(query, {
    variables: { locale, extensionsLang, ...variables },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!locale) {
      return
    }

    //  refetch data when document doesn't translated
    if (required?.length) {
      const reqFields = detectRequiredFields(data, required)
      const isRequiredDetected = typeof reqFields === 'object' ? Object.keys(reqFields).length : reqFields
      if (!isRequiredDetected && ((data && locale !== defaultLang) || (isContentPreview && networkStatus === 8))) {
        refetch({ locale: defaultLang, extensionsLang })
      }
    }
  })

  useEffect(() => {
    if (!locale) {
      return
    }
    refetch({ locale, extensionsLang })
  }, [locale])

  if (loading || !data) {
    return <Loader />
  }

  return <Component {...props} data={data} fetchMore={fetchMore} refetch={refetch} locale={locale} />
}

export default QueryWrapper
