import React from 'react'

export function ProfilePopover() {
  return (
    <div className="intro-popover">
      <div className="intro-popover__body">
        <h2>My courses</h2>
        <p>You can always access this and any other Arduino course by selecting ‘My Courses’ from your profile menu.</p>
      </div>
    </div>
  )
}
