export default function loadZen() {
  var callback = function () {
    window.zESettings = {
      webWidget: {
        helpCenter: {
          originalArticleButton: true,
        },
        offset: {
          vertical: '50px',
          mobile: {
            vertical: '50px',
            horizontal: '0',
          },
        },
        chat: {
          suppress: true,
        },
        contactForm: {
          suppress: true,
        },
        answerBot: {
          suppress: true,
        },
      },
    }

    window.zE('webWidget', 'setLocale', 'en')
  }
  var url = 'https://static.zdassets.com/ekr/snippet.js?key=5f1a89bc-2aa2-4ed9-b120-5e45bb590b23'
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = 'ze-snippet'
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = function () {
      callback()
    }
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}
