import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { rootActions } from '../store/root'
import Loader from '../views/ui-common/Loader/Loader'
import { PROJECT } from '../env'
import CONFIG from '../configurator'
const { isPublicContent } = CONFIG[PROJECT]

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return <Route {...rest} component={(props) => <ConnectedPrivateRouteGuard component={Component} {...props} />} />
}

const PrivateRouteGuard = ({ component: Component, loggedIn, userRole, kitsData, ...props }) => {
  
  if (!loggedIn) {
    return <Loader />
  }

  if (isPublicContent) {
    return <Component {...props} />
  }

  if (loggedIn === true && userRole) {
    if (props.match.params.kind) {
      const kindList = [kitsData.kind, ...kitsData.extensions]
      if (kindList.includes(props.match.params.kind.toUpperCase())) {
        return <Component {...props} />
      } else {
        return <Redirect to="/" />
      }
    }
    return <Component {...props} />
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.object,
  location: PropTypes.object,
}

PrivateRouteGuard.propTypes = {
  component: PropTypes.object,
  loggedIn: PropTypes.bool,
  onLogin: PropTypes.func,
  userRole: PropTypes.string,
  kitsData: PropTypes.object,
  match: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.root.login.loggedIn,
    userRole: state.root.kits.data.role,
    kitsData: state.root.kits.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (redirect) => dispatch(rootActions.login(redirect)),
  }
}

const ConnectedPrivateRouteGuard = connect(mapStateToProps, mapDispatchToProps)(PrivateRouteGuard)

export default PrivateRoute
