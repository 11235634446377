import React from 'react'
import ReactDOM from 'react-dom'

// Necessary to use modern ES6 features like generators (see redux-saga)
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import App from './views/App'
// Import the style of the application root, including arduino-sass
import './styles/index.scss'

ReactDOM.render(React.createElement(App, null, null), document.getElementById('root'))
