import React, { useState, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useTracking } from '../../../hooks/useTracking'

export const LayoutContext = createContext({})

export const LayoutProvider = (props) => {
  const [lastScrollPos, setLastScrollPos] = useState(0)
  const [showHeader, setShowHeader] = useState(true)
  useTracking();

  const handleScroll = (e) => {
    if (lastScrollPos < e.currentTarget.scrollY) {
      setShowHeader(false)
    } else if (lastScrollPos > e.currentTarget.scrollY) {
      setShowHeader(true)
    }
    setLastScrollPos({ lastScrollPos: e.currentTarget.scrollY })
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScroll(e))
    return () => {
      window.removeEventListener('scroll', (e) => handleScroll(e))
    }
  }, [])

  return (
    <LayoutContext.Provider value={{ showHeader }} {...props}>
      {props.children}
    </LayoutContext.Provider>
  )
}

LayoutProvider.propTypes = {
  children: PropTypes.node,
}
