import types from './types'
import { getCookie } from '../../utils/cookies'
import { APP_NAME, FLOW_ENV, PROJECT } from '../../env'
import CONFIG from '../../configurator'

const setBrowserLang = () => {
  const langs = CONFIG[PROJECT].langs[FLOW_ENV]
  const userLang = navigator.language || navigator.userLanguage
  if (userLang) {
    const langKey = userLang.split('-')[0]
    const currentLang = langs.find((item) => {
      return item.id === langKey
    })
    if (currentLang && currentLang.id) return currentLang.id
  }
  return 'en'
}
const initialState = {
  isUpdates: getCookie('is-updates') || CONFIG[PROJECT]?.updates?.defaultState,
  login: {
    didInvalidate: false,
    loggedIn: false,
    error: null,
  },
  profile: {
    data: {},
    error: null,
    language: getCookie(`${APP_NAME}_language`) || setBrowserLang(),
  },
  kits: {
    data: {
      language: '',
      kind: '',
      extensions: [],
    },
    error: null,
  },
  lightbox: null,
  showTeacherTooltips: true,
}

// https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
// prettier-ignore
export default function example(state = initialState, action) {
  switch (action.type) {
  case types.LOGIN_REQUESTED:
    return {
      ...state,
      login: {
        ...state.login,
        loggedIn: false,
      },
    }

  case types.LOGIN_FAILED:
    return {
      ...state,
      login: {
        ...state.login,
        loggedIn: false,
        error: action.error,
      },
    }
  case types.GET_PROFILE_REQUESTED:
    return {
      ...state,
      profile: {
        ...state.profile,
      },
    }
  case types.GET_PROFILE_SUCCEEDED:
    return {
      ...state,
      profile: {
        ...state.profile,
        data: action.profile,
      },
    }
  case types.GET_PROFILE_FAILED:
    return {
      ...state,
      profile: {
        ...state.profile,
        error: action.error,
      },
    }
  case types.GET_KITS_REQUESTED:
    return {
      ...state,
      kits: {
        ...state.kits,
      },
    }
  case types.GET_KITS_SUCCEEDED:
    return {
      ...state,
      kits: {
        ...state.kits,
        data: { ...state.kits.data, ...action.currentKits },
      },
      profile: {
        ...state.profile,
      },
      login: {
        loggedIn: true,
      },
    }
  case types.GET_KITS_FAILED:
    return {
      ...state,
      kits: {
        ...state.kits,
        error: action.error,
      },
    }
  case types.CHANGE_LANGUAGE:
    return {
      ...state,
      profile: {
        ...state.profile,
        language: action.language,
      },
    }
  case types.TRIGGER_LIGHTBOX:
    return {
      ...state,
      lightbox: action.data,
    }
  case types.TRIGGER_TEACHER_TOOLTIPS:
    return {
      ...state,
      showTeacherTooltips: !state.showTeacherTooltips,
    }
  default:
    return state
  }
}
