export class LoginError extends Error {
  constructor() {
    super()
    this.error = 'login_required'
  }
}

export default function authenticatedFetch(input, init) {
  let patchedInit = init
  if (!init || typeof init !== 'object') {
    patchedInit = {}
  }

  return window.auth
    .getAccessToken()
    .then((authToken) => {
      const patchedHeaders = Object.assign(patchedInit.headers || {}, {
        Authorization: `Bearer ${authToken}`,
      })

      if (!authToken) {
        throw new LoginError()
      }

      patchedInit = Object.assign(patchedInit, {
        headers: patchedHeaders,
      })

      return fetch(input, patchedInit)
    })
    .catch((e) => {
      if (e.error === 'login_required') {
        window.auth.login({ href: window.location.href, loginCoppa: process.env.IS_MINOR && 'login-selector' })
      }
    })
}
