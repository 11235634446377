import { PROJECT, FLOW_ENV } from '../env'
import CONFIG from '../configurator'
import { ProjectList, hasExtendedAccess } from './constants'
import translate from '../locale/translate'

const langs = CONFIG[PROJECT].langs[FLOW_ENV]

function clickHandler(e, link, isExternal) {
  if (isExternal) {
    window.location.href = link
    return
  }

  window._push(link)
  window.header.closeMenu()
  e.preventDefault()
}

export default (footerOptions, headerOptions, userRole) => {
  try {
    generateFooter(footerOptions, userRole)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
  try {
    generateHeader(headerOptions, userRole)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

const generateFooter = ({ onLanguageChange, selectedLanguage }) => {
  const langsSettings = {
    data: langs,
    selectedId: selectedLanguage,
    onSelect: onLanguageChange,
  }
  
  if (window.header.rebuildLanguage) {
    window.header.rebuildLanguage(langsSettings)
  } else if (window.footer.rebuildLanguage) {
    window.footer.rebuildLanguage(langsSettings)
  }

}

const generateHeader = ({ formatMessage, products, kitsData }, userRole) => {
  const navList = []
  const kits = [kitsData.kind, ...kitsData.extensions]

  // CONTENTPREVIEW
  if (PROJECT === ProjectList.CONTENTPREVIEW) {
    const mainItem = {
      name: translate('products'),
    }
    const localeMap = {
      highSchool: 'high_school',
      middleSchool: 'middle_school',
      university: 'university',
    }
    const { highSchool, middleSchool, university } = products.modules[0]

    const contentList = {
      middleSchool,
      highSchool,
      university,
    }
    mainItem.subMenu = Object.keys(contentList).map((item) => {
      return {
        name: translate(localeMap[item]),
        extend: [
          {
            links: contentList[item].map((link) => {
              const URL = link.customLink || `/content-preview/${localeMap[item]}/${link.template}/${link.kitKind}`
              return {
                name: link.title,
                url: URL,
                onClick: (e) => clickHandler(e, URL, Boolean(link.customLink)),
              }
            }),
          },
        ],
      }
    })

    navList.push(mainItem)
  }

  //ENGINEERING_KIT
  if (PROJECT === ProjectList.ENGINEERING_KIT) {
    const lessonItem = {
      name: translate('getting_started'),
      extend: [
        {
          links: buildSimpleMenu((products.modules && products.modules[0]) || {}, kits, 'lesson'),
        },
      ],
    }

    const productItem = {
      name: translate('activities'),
      extend: [
        {
          links: buildSimpleMenu((products.modules && products.modules[0]) || {}, kits, 'project'),
        },
      ],
    }
    navList.push(lessonItem, productItem)
  }

  //STARTER_KIT || STUDENT_KIT || ProjectList.IOT_SK
  if (PROJECT === ProjectList.STARTER_KIT || PROJECT === ProjectList.STUDENT_KIT || PROJECT === ProjectList.IOT_SK) {
    const [gettingStarted, ...links] = buildSimpleMenu((products.modules && products.modules[0]) || {}, kits, 'lesson')

    const lessonItem = {
      name: PROJECT === ProjectList.IOT_SK ? translate('activities') : translate('lessons'),
      extend: [
        {
          links: links.map((item, index) => {
            return {
              ...item,
              name: `${index + 1}. ${item.name}`,
            }
          }),
        },
      ],
    }

    navList.push(gettingStarted, lessonItem)
  }

  if (PROJECT === ProjectList.BRACCIO) {
    const [gettingStarted, ...links] = buildSimpleMenu((products.modules && products.modules[0]) || {}, kits, 'lesson')
    const projects = buildSimpleMenu((products.modules && products.modules[0]) || {}, kits, 'project')

    const lessonItem = {
      name: translate('lessons'),
      extend: [
        {
          links: links.map((item, index) => {
            return {
              ...item,
              name: `${index + 1}. ${item.name}`,
            }
          }),
        },
      ],
    }

    const projectItem = {
      name: translate('projects'),
      extend: [
        {
          links: projects.map((item, index) => {
            return {
              ...item,
              name: `${index + 1}. ${item.name}`,
            }
          }),
        },
      ],
    }

    navList.push(gettingStarted, lessonItem, projectItem)
  }

  // CTC-GO
  if (PROJECT === ProjectList.CTC_GO) {
    const mainItem = {
      name: formatMessage({
        id: 'headerModules',
      }),
    }
    const localeMap = {
      modules: 'Core',
      extensions: 'Motion',
    }

    mainItem.subMenu = Object.keys(products).map((item) => {
      return {
        name: localeMap[item],
        extend: products[item].map((module, index) => {
          const moduleURL = `/${module.kitKind.toLowerCase()}/module/${module.slug}`
          return {
            module: {
              name: `PART ${++index}`,
              url: moduleURL,
              onClick: (e) => clickHandler(e, moduleURL),
            },
            blocks: [
              {
                name: translate('lessons'),
                links: buildSimpleMenu(module, kits, 'lesson'),
              },
              {
                name: translate('activities'),
                links: buildSimpleMenu(module, kits, 'project'),
              },
            ],
          }
        }),
      }
    })
    navList.push(mainItem)
  }

  // CTC101
  if (PROJECT === ProjectList.CTC101) {
    const mainItem = {
      name: formatMessage({
        id: 'headerModules',
      }),
    }

    const { publicModules, privateModules } = products.modules.reduce(
      (result, item) => {
        if (item.id === '25270576') {
          result.privateModules = item
          return result
        }
        result.publicModules.push(item)
        return result
      },
      { publicModules: [], privateModules: {} },
    )

    mainItem.subMenu = publicModules.map((module) => {
      const moduleURL = `/${module.kitKind.toLowerCase()}/module/${module.slug}`
      return {
        name: module.title,
        url: moduleURL,
        onClick: (e) => clickHandler(e, moduleURL),
        extend: [
          {
            blocks: [
              {
                name: translate('lessons'),
                links: buildSimpleMenu(module, kits, 'lesson'),
              },
              {
                name: translate('activities'),
                links: buildSimpleMenu(module, kits, 'project'),
              },
            ],
          },
        ],
      }
    })

    navList.push(mainItem)
    // only private module
    if (hasExtendedAccess(userRole)) {
      const moduleURL = `/${privateModules.kitKind.toLowerCase()}/module/${privateModules.slug}`
      const subMenu = [
        {
          name: translate('training'),
          url: moduleURL,
          onClick: (e) => clickHandler(e, moduleURL),
          extend: [
            {
              blocks: [
                {
                  // name: translate('lessons'),
                  links: buildSimpleMenu(privateModules, kits, 'lesson'),
                },
              ],
            },
          ],
        },
      ]
      if (kitsData._kind === 'CTC101') {
        subMenu.push({
          name: translate('webinar_booking'),
          url: '/guide/training-webinars',
          onClick: (e) => clickHandler(e, '/guide/training-webinars'),
        })
      }

      navList.push({
        name: translate('educators'),
        subMenu: subMenu,
      })
    }
  }

  // SCIENCE KIT
  if (PROJECT === ProjectList.PHYSICS_LAB) {
    const { modules, extensions } = products

    const [gettingStarted, arduinoCode] = buildSimpleMenu((modules && modules[0]) || {}, kits, 'lesson')

    const modulesItem = {
      name: translate('experiments'),
      extend: [
        {
          blocks: [],
        },
      ],
    }

    modulesItem.extend[0].blocks = extensions
      .filter((item) => {
        return !item.comingSoon
      })
      .map((item) => {
        return {
          name: item.title,
          links: buildSimpleMenu(item, kits, 'project'),
        }
      })
    navList.push(gettingStarted, arduinoCode, modulesItem)
  }

  if (PROJECT === ProjectList.GREENHOUSE) {
    const { modules } = products

    const modulesItem = {
      name: translate('modules'),
    }
    const modulesList = modules
      .map((item) => {
        return buildSimpleMenu(item, kits, 'lesson')
      })
      .map((list) => {
        return list[0]
      })

    const gettingStarted = {
      name: translate('getting_started'),
      extend: [
        {
          links: modulesList,
        },
      ],
    }

    modulesItem.subMenu = modules
      .filter((item) => {
        return !item.comingSoon
      })
      .map((item) => {
        return {
          name: item.title,
          url: `/${item.kitKind.toLowerCase()}/module/${item.slug}`,
          onClick: () => window._push(`/${item.kitKind.toLowerCase()}/module/${item.slug}`),
          extend: [
            {
              blocks: [
                {
                  name: translate('lessons'),
                  links: buildSimpleMenu(item, kits, 'lesson', true),
                },
                {
                  name: translate('activities'),
                  links: buildSimpleMenu(item, kits, 'project'),
                },
              ],
            },
          ],
        }
      })
    navList.push(gettingStarted, modulesItem)
  }
  // SCIENCE JOURNAL
  if (PROJECT === ProjectList.SJ) {
    const { modules, extensions } = products

    const modulesItem = {
      name: translate('modules'),
    }
    const modulesList = modules
      .map((item) => {
        return buildSimpleMenu(item, kits, 'lesson')
      })
      .map((list) => {
        return list[0]
      })

    const gettingStarted = {
      name: translate('getting_started'),
      extend: [
        {
          links: modulesList,
        },
      ],
    }

    modulesItem.subMenu = extensions
      .filter((item) => {
        return !item.comingSoon
      })
      .map((item) => {
        return {
          name: item.title,
          url: `/${item.kitKind.toLowerCase()}/module/${item.slug}`,
          extend: [
            {
              blocks: [
                {
                  name: translate('lessons'),
                  links: buildSimpleMenu(item, kits, 'lesson'),
                },
                {
                  name: translate('activities'),
                  links: buildSimpleMenu(item, kits, 'project'),
                },
              ],
            },
          ],
        }
      })
    navList.push(gettingStarted, modulesItem)
  }

  // OPLA kit
  if (PROJECT === ProjectList.OPLA || PROJECT === ProjectList.MAKEYOURUNO) {
    const { modules, extensions } = products

    const modulesItem = {
      name: 'Projects',
    }
    const modulesList = modules
      .map((item) => {
        return buildSimpleMenu(item, kits, 'lesson')
      })
      .map((list) => {
        return list[0]
      })

    const gettingStarted = {
      name: translate('getting_started'),
      extend: [
        {
          links: modulesList,
        },
      ],
    }

    modulesItem.extend = extensions
      .filter((item) => item.showOnHomePage)
      .map((item) => {
        return {
          links: buildSimpleMenu(item, kits, 'lesson'),
        }
      })

    navList.push(gettingStarted, modulesItem)
  }

  // SENSOR KIT
  if (PROJECT === ProjectList.SENSORKIT) {
    const { modules, extensions } = products

    const modulesItem = {
      name: translate('lessons'),
    }
    const [gettingStarted] = modules
      .map((item) => {
        return buildSimpleMenu(item, kits, 'lesson')
      })
      .map((list) => {
        return list[0]
      })

    modulesItem.extend = extensions.map((item) => {
      return {
        links: buildSimpleMenu(item, kits, 'lesson'),
      }
    })

    navList.push(gettingStarted, modulesItem)
  }

  if (CONFIG[PROJECT].glossary) {
    navList.push({
      name: formatMessage({
        id: 'headerGlossary',
      }),
      url: '/glossary',
      onClick: (e) => clickHandler(e, '/glossary'),
    })
  }

  if (PROJECT === ProjectList.CTC_GO) {
    if (hasExtendedAccess(userRole)) {
      navList.push({
        name: translate('webinar_booking'),
        url: '/guide/webinars',
        onClick: (e) => clickHandler(e, '/guide/webinars'),
      })
    }
  }

  if (CONFIG[PROJECT].resources) {
    navList.push({
      name: formatMessage({
        id: 'headerGlossary',
      }),
      url: '/resources',
      onClick: (e) => clickHandler(e, '/resources'),
    })
  }

  window.header.renderMenu(navList)
}

const checkAccess = ({ kitKind }, list = []) => {
  if (kitKind.includes('+')) {
    return kitKind.split('+').some((i) => list.includes(i))
  }
  return list.includes(kitKind)
}

function buildSimpleMenu(module, kits, type = 'lesson', skipFirst) {
  const list = module[`${type}s`].map((item, index) => {
    if (!checkAccess(item, kits) || (skipFirst && index === 0)) {
      return null
    }
    const kind = module.kitKind.toLowerCase()
    const url = `/${kind}/module/${module.slug}/${type}/${item.slug}`
    return {
      url,
      name: item.title,
      onClick: (e) => clickHandler(e, url),
    }
  })

  return list.filter(Boolean)
}
