/* eslint-disable no-console */
import { call, put, takeEvery, fork, select } from 'redux-saga/effects'
import actions from './actions'
import AuthenticationTypes from './types'
import { PROJECT, APP_URL } from '../../env'
import { getKits, getKitsByOrganization, getRoleFromOrganizations } from '../../apis/kits'
import { AppAccessible, CLASSROOM_DASHBOARD, ProjectList } from '../../utils/constants'
import CONFIG from '../../configurator'
import { getSubscriptions } from '../../apis/profile'

const getUserID = (state) => state.root?.profile?.data['http://arduino.cc/id']

const DEFAULT_KIT = {
  kind: AppAccessible[PROJECT],
  language: 'en',
  role: 'student',
}
export const PAID_PLANS = {
  // This is an employees-only plan
  arduino: 'Arduino',
  'create-entry': 'Entry',
  'create-maker': 'Maker',
  'create-maker-plus': 'Maker Plus',
  'cloud-enterprise': 'Enterprise',
  'cloud-classroom': 'School',
  'organization-member': 'Organization Member',
}

export const checkIfPlanExist = (plans) => {
  if (!Array.isArray(plans)) return false
  return plans.some((item) => Object.keys(PAID_PLANS).includes(item))
}

export function decodeToken(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

const { isPublicContent } = CONFIG[PROJECT]
export const isStudentKit = PROJECT === ProjectList.STUDENT_KIT
/**
 * Checks if the user is authenticated. If not, tries
 * to authenticate via the header-footer Auth0 method.
 */
const login = function* login() {
  yield put(actions.loginRequested())
  // value for content-preview project
  if (isPublicContent) {
    console.log('content is public')
    yield put(
      actions.getKitsSucceeded({
        ...DEFAULT_KIT,
        role: 'admin',
      }),
    )
  }
  try {
    // get token
    const token = yield call(window.auth.getAccessToken)
    // get profile
    yield put(actions.loginSucceeded(token))
  } catch (error) {
    if (!isPublicContent) {
      if (error.error === 'login_required') {
        return window.auth.login({ href: APP_URL, loginCoppa: process.env.IS_MINOR && 'login-selector' })
      } else {
        yield put(actions.loginFailed(error))
      }
    }
  }
}

// get Profile
const getProfile = function* getProfile({ token }) {
  yield put(actions.getProfileRequested())
  try {
    const decoded = decodeToken(token)
    const isMinor = Boolean(decoded['http://arduino.cc/is_minor'])

    const profile = yield call(window.auth.getProfile)
    const recap = yield call(getSubscriptions)
    return yield put(actions.getProfileSucceeded({ ...profile, isMinor, recap }))
  } catch (error) {
    yield put(actions.getProfileFailed(error))
  }
}

const watchLogin = function* watchLogin() {
  yield takeEvery(AuthenticationTypes.LOGIN, login)
}

const watchLoginSuccess = function* watchLoginSuccess() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, loginSuccess)
}

const loginSuccess = function* loginSuccess(token) {
  yield fork(() => getProfile(token))
}

const watchGetProfile = function* watchGetProfile() {
  yield takeEvery(AuthenticationTypes.GET_PROFILE, loginSuccess)
}

const getKitsSaga = function* getKitsSaga() {
  yield put(actions.getKitsRequested())
  try {
    const kits = yield call(getKits)
    const { plans = [] } = yield call(getSubscriptions)
    const isPlanExist = yield call(checkIfPlanExist, plans)
    const userID = yield select(getUserID)

    const findCurrentKit = (kits) => {
      const list = kits.filter((kit) => {
        if (PROJECT === ProjectList.CTC101) {
          return kit.kind === AppAccessible[PROJECT] || kit.kind === 'CTC101SELF'
        } else {
          return kit.kind === AppAccessible[PROJECT]
        }
      })

      if (!list.length) return null

      // find if user have teacher account
      const isEducator = list.some((kit) => kit.role === 'admin' || kit.role === 'teacher')

      const extensions = []
      if (PROJECT === ProjectList.CTC_GO) {
        // only for CTC-GO
        const isHaveExtension = list.find((kit) => kit.extensions.length)
        if (isHaveExtension || isPlanExist) {
          extensions.push('CTCGOMOTION')
        }
      }

      return {
        ...DEFAULT_KIT,
        role: isEducator ? 'teacher' : 'student',
        extensions,
      }
    }

    // get personal kits
    let currentKit = findCurrentKit(kits)

    if (!currentKit) {
      // get organization kits
      const organizationKits = yield call(getKitsByOrganization)
      currentKit = findCurrentKit(organizationKits?.kits)
    }

    // if currentKit is null, and user have plan we will use default kit
    if (!currentKit && isPlanExist) {
      const { role } = yield call(getRoleFromOrganizations, userID)

      currentKit = {
        ...DEFAULT_KIT,
        role: role?.replace('role:edu-', '') || 'student',
      }

      if (PROJECT === ProjectList.CTC_GO) {
        currentKit.extensions = ['CTCGOMOTION']
      }
    }

    // otherwise redirect to classroom
    if (!currentKit) {
      window.location.href = CLASSROOM_DASHBOARD
    }

    // magic related legacy CTC101
    if (PROJECT === ProjectList.CTC101) {
      currentKit._kind = currentKit.kind
      currentKit.kind = AppAccessible[PROJECT]
    }

    yield put(actions.getKitsSucceeded(currentKit))
  } catch (error) {
    yield put(actions.getKitsFailed(error))
  }
}

const watchGetKits = function* watchGetKits() {
  yield takeEvery(AuthenticationTypes.LOGIN_SUCCEEDED, getKitsFork)
}

const getKitsFork = function* getKitsFork() {
  if (!isPublicContent) {
    yield fork(getKitsSaga)
  }
}

export default [watchLogin, watchLoginSuccess, watchGetProfile, watchGetKits]
