import React from 'react'
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Provider } from 'react-redux'

import App from './App'

import createStore from '../../store/createStore'

import { DATOCMS_API, DATOCMS_API_TOKEN, DATOCMS_GQL_API } from '../../env'

const httpLink = createHttpLink({
  uri: DATOCMS_GQL_API || DATOCMS_API,
})

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = DATOCMS_API_TOKEN || (await window.auth.getAccessToken())
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token || ''}`,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export const store = createStore()

const AppContainer = () => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  )
}

export default AppContainer
