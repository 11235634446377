import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { rootActions } from '../../store/root'
import { LayoutProvider } from '../ui-common/Layout/layout-context'
import LayoutContainer from '../ui-common/Layout/LayoutContainer'
import Loader from '../ui-common/Loader/Loader'
import PrivateRoute from '../../utils/PrivateRoute'
import CONFIG from '../../configurator'
import { BASE_URL, PROJECT } from '../../env'

import '../../styles/App.scss'

import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import { ProjectList } from '../../utils/constants'

const Home = React.lazy(() => import('../Home/HomeContainer'))

const Glossary = React.lazy(() => import('../Glossary/GlossaryContainer'))
const Resources = React.lazy(() => import('../Resources'))
const ModulesList = React.lazy(() => import('../ModulesList'))
const Lesson = React.lazy(() => import('../Lesson/LessonContainer'))
const Project = React.lazy(() => import('../Project/ProjectContainer'))
const Guide = React.lazy(() => import('../Guide/GuideContainer'))
const CPLessonProvider = React.lazy(() => import('../ContentPreview/lesson/CPLessonProvider'))
const CPProjectProvider = React.lazy(() => import('../ContentPreview/project/CPProjectProvider'))

const AppComponent = ({ onLogin, loggedIn }) => {
  // check and make authenticate
  useEffect(() => {
    if (!loggedIn) {
      onLogin()
    }
  }, [])

  return (
    <BrowserRouter basename={BASE_URL}>
      <Suspense fallback={<Loader />}>
        <LayoutProvider>
          <LayoutContainer>
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute exact path="/:kind/module/:module/lesson/:lessonSlug" component={Lesson} />
              <PrivateRoute exact path="/:kind/module/:module/project/:projectSlug" component={Project} />
              <PrivateRoute exact path="/guide/:guideSlug" component={Guide} />

              {CONFIG[PROJECT].resources && <PrivateRoute exact path="/resources" component={Resources} />}
              {CONFIG[PROJECT].glossary && (
                <PrivateRoute key="/glossary" exact path="/glossary" component={Glossary} />
              )}
              {!(CONFIG[PROJECT].single || CONFIG[PROJECT].lessonsList) && (
                <PrivateRoute exact path="/:kind/module/:module" component={ModulesList} />
              )}
              {PROJECT === ProjectList.CONTENTPREVIEW && [
                <PrivateRoute
                  exact
                  key="lesson"
                  path="/content-preview/:type/lesson/:kind"
                  component={CPLessonProvider}
                />,
                <PrivateRoute
                  exact
                  key="project"
                  path="/content-preview/:type/project/:kind"
                  component={CPProjectProvider}
                />,
              ]}
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </LayoutContainer>
        </LayoutProvider>
      </Suspense>
    </BrowserRouter>
  )
}

AppComponent.propTypes = {
  loggedIn: PropTypes.bool,
  onLogin: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.root.login.loggedIn,
    kitsData: state.root.kits.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (redirect) => dispatch(rootActions.login(redirect)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent)
