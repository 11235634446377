import React from 'react'

export function MarkerTrialPopover() {
  return (
    <div className="intro-popover">
      <div className="intro-popover__body">
        <h2>Activate your free monthly Maker Plan</h2>
        <p>By purchasing Arduino Oplà, you get 12 months free subscription to Arduino Create Maker Plan.</p>
        <p>
          1. Select the monthly Maker Plan
          <br />
          2. Sign in with your Arduino account
          <br />
          3. Add your billing info
          <br />
          4. Insert the code you found inside the box
          <br />
          5. Get coding
          <br />
        </p>
        <p>If you haven’t got your Arduino Oplà IoT Kit yet, check it out on our store.</p>
      </div>
    </div>
  )
}
